<template>
  <div>
    <v-card
      flat
    >
      <v-card-text>
        <v-row>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.date_of_deposit"
                label="Date Deposited"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.time_of_deposit"
                label="Time Deposited"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.bank_depositories.bank_code"
                label="Bank Code"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="deposit_slips.bank_depositories.account_no"
                label="Bank Account #"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="amount_formated"
                label="Amount"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="balance_formated"
                label="Balance"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table dense
                  :headers="headers"
                  :items="liquidations"
                  :search="search"
                  disable-pagination
                  hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Members Not Yet Deposited Data</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            class="mx-2"
            :append-icon="icons.mdiAccountSearch"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.date }}
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.share_from }}
          </td>
          <td>
            {{ item.amount }}
          </td>
          <td class="text-center">
            <v-icon
              v-show="item.deposit_slip_id===null"
              v-if="!is_saving"
              class="mr-2"
              color="success"
              @click="load_to_deposit(item)"
            >
              {{icons.mdiCheck}}
            </v-icon>
            <v-icon
              v-show="item.deposit_slip_id!=null"
              v-if="!is_deleting"
              class="mr-2"
              color="error"
              @click="remove_to_deposit(item)"
            >
              {{icons.mdiDelete}}
            </v-icon>
            <v-progress-circular color="info" indeterminate
                                 v-if="is_saving||is_deleting"></v-progress-circular>
          </td>
        </tr>
      </template>
    </v-data-table>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiCheck, mdiDelete} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {

      is_saving: false,
      is_deleting: false,
      selected_data: {},

      or_date: '',
      or_no: '',
      amount: '',

      search: '',
      headers: [
        {text: 'Date', value: 'members.date_registered', sortable: true},
        {text: 'Name', value: 'members.company_name', sortable: true},
        {text: 'From', value: 'members.email_address', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
        {text: 'Action', align: 'center', value: 'view_owners', sortable: true},
      ],
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiDelete,
          mdiCloudUploadOutline,
          mdiCheck,
        },
      }
    },
    data() {
      return initialState()
    },
    props: {
      balance_formated: String,
      amount_formated: String,
      deposit_slips: Object,
      liquidations: Array,
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      reset() {
        Object.assign(this.$data, initialState())
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      load_to_deposit(item) {
        this.is_saving = true
        this.selected_data = item
        this.amount = this.formatPrice(item.amount)
        this.$emit('data', {
          balance: parseFloat(this.deposit_slips.balance) - parseFloat(this.selected_data.amount),
          id: this.selected_data.id,
          deposit_slip_id: this.deposit_slips.id,
        })
        this.reset()
      },
      remove_to_deposit(item) {
        this.$emit('remove', {
          balance: parseFloat(this.deposit_slips.balance) - parseFloat(item.amount),
          id: item.id,
        })
        this.is_saving = false
      },
    }
  }
</script>
